import React, {useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'react-phone-input-2/lib/style.css';
import {Formik, Field, Form} from 'formik';

import axios from 'axios';
import {config} from '../components/Constant';
import Toast from 'react-bootstrap/Toast';
import {Link, useNavigate} from 'react-router-dom';
import ToastContainer from 'react-bootstrap/ToastContainer';
import {useDispatch} from 'react-redux';
import {setUser, setUserID} from '../actions';
import OuterHeader from './OuterHeader';
import Adoro from '../images/Adoro.png';

const ToastComp = ({show, onClose}) => {
  return (
    <ToastContainer position={'top-center'}>
      <Toast show={show.show} delay={3000} autohide onClose={onClose}>
        <Toast.Header>
          <strong className="me-auto">ALERT</strong>
        </Toast.Header>
        <Toast.Body>{show.msg}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
const SignIn = () => {
  const [show, setShow] = useState ({show: false, msg: ''});
  const navigate = useNavigate ();
  const toggleShow = () => setShow ({show: false, msg: ''});

  const dispatch = useDispatch ();

  return (
    <section className="get-started">
      <ToastComp show={show} onClose={toggleShow} />

      <Container>
        <Row className="justify-content-center">
          <Col md={6} sm={12}>
            <div className="form-heading">
              <img
                src={Adoro}
                style={{height: '20%', width: '20%', cursor: 'pointer'}}
                onClick={() => (window.location.href = 'https://adoro.social/')}
              />
              <h2>Welcome back</h2>
              <p>
                Login to your brand dashboard and start managing your campaigns,
                order and much more.{' '}
              </p>
            </div>
            <Formik
              className="contact-us"
              initialValues={{
                email: '',
                password: '',
              }}
              onSubmit={async values => {
                console.log("onSubmit triggered with values:", values); // ✅ Check if form is submitting

                await axios
                  .post (`${config.API_URL}/user/login`, values)
                  .then (res => {
                    console.log('response isss',res)
                    if (res.status === 200) {
                      dispatch (setUserID (res.data.user));
                      // let USER = {
                      //   first_name: res.data.user.FirstName,
                      //   userId: res.data.userId,
                      //   isLoggin: true,
                      // };
                      let USER = {
                        first_name: res.data.user.Firstname,
                        email: res.data.user.Email,
                        userId: res.data.userId,
                        isLoggin: true,
                      };

                      sessionStorage.setItem ('USER', JSON.stringify (USER));
                      navigate ('/dashboard/Dashboard');
                      window.location.reload (false);
                    } else {
                      
                      setShow ({
                        show: true,
                        msg: 'Incorrect Email and Password',
                      });
                    }
                  }).catch(err =>{
                    console.log('erorrrrrrrrrr',err)
                    setShow({
                      msg:"Invalid email or password"
                    })

                  });
              }}
            >
              <Form>
                <Field
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  required
                />

                <Field
                  id="password"
                  name="password"
                  placeholder="Password"
                  type="password"
                  required
                />

                <button type="submit" className="custom-btn">
                  Log In
                </button>
              </Form>
            </Formik>

            <div style={{ display: "flex", justifyContent: "space-between",  width: "100%" }}>
  <Link to="/forgot-password">Forgot Password?</Link>
  <Link to="/">Oops! Forgot to Signup</Link>
</div>

          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SignIn;
