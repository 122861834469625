import React, { useState } from "react";
// import Logo from '../images/logo.png';
import { Container, Row, Col } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Formik, Field, Form } from "formik";
import axios from "axios";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../actions";
import { config } from "../components/Constant";
import * as Yup from "yup";
import Adoro from '../images/Adoro.png';

const ToastComp = ({ show, onClose }) => {
  return (
    <ToastContainer position={"top-center"}>
      <Toast show={show.show} delay={3000} autohide onClose={onClose}>
        <Toast.Header>
          <strong className="me-auto">ALERT</strong>
        </Toast.Header>
        <Toast.Body>{show.msg}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

const validationSchema = Yup.object().shape({
  first_name: Yup.string().min(2, "Too Short").required("Required"),
  last_name: Yup.string().min(2, "Too Short").required("Required"),
  company_name: Yup.string().min(2, "Too Short").required("Required"),
  email: Yup.string()
    .email("The email must be a valid email address.")
    .required("The Email field is required"),
  password: Yup.string()
    .required("The Password field is required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*)[A-Za-z\d]{8,}$/,
      `Must Contain 8 Characters, One Uppercase, One Lowercase,
        One Number`
    ),
});

const GetStarted = () => {
  const [phone, setPhone] = useState(null);
  const [show, setShow] = useState({ show: false, msg: "" });
  const navigate = useNavigate();
  const toggleShow = () => setShow({ show: false, msg: "" });
  const dispatch = useDispatch();

  return (
    <section className="get-started">
      <ToastComp show={show} onClose={toggleShow} />
      <Container>
        <Row className="justify-content-center">
          <Col md={6} sm={12}>
            <div className="form-heading">
            <img
                src={Adoro}
                style={{height: '20%', width: '20%', cursor: 'pointer'}}
                onClick={() => (window.location.href = 'https://adoro.social/')}
              />
              <h2>Get Started</h2>
              <p>Memes that helps you to reach the right audience</p>
            </div>
            <Formik
              className="contact-us"
              initialValues={{
                first_name: "",
                last_name: "",
                company_name: "",
                email: "",
                password: "",
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const res = await axios.post(`${config.API_URL}/user/signup`, {
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                    company_name: values.company_name,
                    mobileNo: phone,
                    password: values.password,
                  });
                  if (res.status !== 200) {
                    setShow({
                      show: true,
                      msg: res.data.msg,
                    });
                  } else {
                    let user = {
                      first_name: values.first_name,
                      last_name: values.last_name,
                      email: values.email,
                      company_name: values.company_name,
                      mobileNo: phone,
                      password: values.password,
                      isAllowed: true,
                    };
                    let USER = {
                      first_name: values.first_name,
                      email: values.email,
                      userId: 0,
                      isLoggin: true,
                    };
                    sessionStorage.setItem("USER", JSON.stringify(USER));
                    dispatch(setUser(user));
                    navigate("/info");
                  }
                } catch (error) {
                  console.log('errro',error)
                  const errorMsg = error.response?.data?.msg || "An error occurred. Please try again.";
                  setShow({
                    show: true,
                    msg: errorMsg,
                  });
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <Row>
                    <Col md={6} sm={12}>
                      <Field name="first_name" placeholder="First Name" />
                      {errors.first_name && touched.first_name ? (
                        <div>{errors.first_name}</div>
                      ) : null}
                    </Col>
                    <Col md={6} sm={12}>
                      <Field name="last_name" placeholder="Last Name" />
                      {errors.last_name && touched.last_name ? (
                        <div>{errors.last_name}</div>
                      ) : null}
                    </Col>
                  </Row>
                  <Field name="company_name" placeholder="Company Name" />
                  {errors.company_name && touched.company_name ? (
                    <div>{errors.company_name}</div>
                  ) : null}
                  <PhoneInput
                    inputProps={{
                      name: "mobileNo",
                      required: true,
                    }}
                    country={"in"}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                  />
                  <Field
                    id="email"
                    name="email"
                    placeholder="Email"
                    type="string"
                  />
                  {errors.email && touched.email ? (
                    <div>{errors.email}</div>
                  ) : null}
                  <Field
                    id="password"
                    name="password"
                    placeholder="Password"
                    type="password"
                  />
                  {errors.password && touched.password ? (
                    <div>{errors.password}</div>
                  ) : null}
                  <button type="submit" className="custom-btn">
                    Continue
                  </button>
                </Form>
              )}
            </Formik>
            <div className="form-bottom text-center mt-4">
              <p>
                By signing up you agree to our <strong>Terms of Service</strong>{" "}
                and <strong>Privacy Policy</strong>
              </p>
              <p>Already Have an account? <Link to="/sign-in">Sign-In</Link></p>
          
               
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default GetStarted;
